<template>
  <div class="menu">
   
    <router-link to='/ldgxxyUserCenter/learning' class="sub learning" :class="$route.name=='Learning'?'active':''">学习记录</router-link>
    <router-link to='/ldgxxyUserCenter/classInfo' class="sub class" :class="$route.name=='ClassInfo'?'active':''">班级信息</router-link>
    <router-link to='/ldgxxyUserCenter/certificate' class="sub collect" :class="$route.name=='certificate'?'active':''">领取证书</router-link>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: 'UserMenu',
  data(){
    return{
      comInfo:''
    }
  },
  components: {
    
    
  },
   computed: {
    ...mapState(['userInfo'])
  },
  mounted(){
    this.comInfo = JSON.parse(localStorage.getItem('siteInfo'))
    // if(!this.userInfo){
    //   location.reload(); 
    // }

  },
}
</script>

<style lang="scss">
.menu{
  box-sizing: border-box;
  width: 352px;
  background: url(~@/assets/menu-bg.png) no-repeat center bottom #fff;
  background-size: 100% auto;
  float: left;
  .sub{
    display: block;
    padding-left: 110px;
    height: 54px;
    line-height: 58px;
    font-size: 18px;
    color: #333;
    margin-bottom: 4px;
    cursor: pointer;
    &.material{
      background: url(~@/assets/material-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/material-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.learning{
      background: url(~@/assets/learning-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/learning-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.phone{
      background: url(~@/assets/phone-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/phone-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.pay{
      background: url(~@/assets/pay-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/pay-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.class{
      background: url(~@/assets/class-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/class-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.exam{
      background: url(~@/assets/exam-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/exam-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.message{
      background: url(~@/assets/message-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/message-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    &.collect{
      background: url(~@/assets/collect-grey-ico.png) no-repeat 53px 18px;
      background-size: 24px;
      &:hover,
      &.active{
        background: url(~@/assets/collect-white-ico.png) no-repeat 53px 18px #254ED4;
        background-size: 24px;
      }
    }
    a{
      font-size: 18px;
      color: #333;
    }
    &:hover,
    &.active{
      color: #fff;
    }
  }
}
</style>
